import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import CarouselBg from "../components/CarouselBg"
import has from "lodash.has"
import Image from "gatsby-image"
import get from "lodash.get"
import { css } from "@emotion/core"
import { Helmet } from "react-helmet"
import slugify from "../util/slugify"

export default function Post({ data }) {
  return (
    <Layout>
      <Helmet>
      <title>
      {get(data, "post.frontmatter.seotitle")} | Softful - Software company Auckland NZ
        </title>
      </Helmet>
      <CarouselBg>
        <div className="container">
          <div className="row">
            <div className="col-sm-12   ">
              <div
                css={css`
                  padding: 50px 0;
                  h1,
                  h3 {
                    font-weight: normal;
                    text-align: left;
                    padding: 0;
                    color: white;
                  }

                  .title {
                    font-size: 36px;
                    margin-bottom: 1rem;

                    /* @md */
                    @media (min-width: 768px) {
                      font-size: 40px;
                      line-height: 0.5;
                      letter-spacing: 0.072em
                    }
                  }
                  .info {
                    opacity: 0.8;
                    font-weight: 500;
                    font-size: 20px;
                    /* @md */
                    @media (min-width: 768px) {
                      font-size: 24px;
                    }
                  }
                `}
              >
                <h1 className="title">{get(data, "post.frontmatter.title")}</h1>
              </div>

              {has(data, "in_pic.sharp.fluid") && (
                <div
                  className="in-pic-1 in-pic-height"
                  style={{ float: "right" }}
                >
                  {" "}
                  <Image
                    style={{ height: "100%" }}
                    fluid={data.in_pic.sharp.fluid}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </CarouselBg>
      <div className="breadcrums">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Link to="/">Home</Link> - <Link to="/blog/">News</Link> -{" "}
              {get(data, "post.frontmatter.title")}
            </div>
          </div>
        </div>
      </div>
      <div className="content-holder">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12">
              {has(data, "post.frontmatter.featured.sharp.fluid") && (
                <div
                  className="post-featured-image"
                  style={{
                    maxWidth:
                      data.post.frontmatter.featured.sharp.fluid
                        .presentationWidth,
                  }}
                >
                  <Image
                    fluid={get(data, "post.frontmatter.featured.sharp.fluid")}
                  />
                </div>
              )}
              <div
                dangerouslySetInnerHTML={{ __html: get(data, "post.html", "") }}
              ></div>
              <div>
                {get(data, "post.frontmatter.tags", []).map((tag) => {
                  return (
                    <Link
                      to={`/blog/tag/${slugify(tag)}/`}
                      key={tag}
                      css={css`
                        margin-right: 1rem;
                      `}
                    >
                      {tag}
                    </Link>
                  )
                })}
              </div>
              <div
                css={css`
                  padding-top: 1rem;
                `}
              >
                {" "}
                <strong> Published: </strong>
                {get(data, "post.frontmatter.date")}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($id: String) {
    post: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        seotitle
        featured {
          sharp: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
        date(formatString: "DD/MM/YYYY")
        tags
      }
      html
    }
  }
`

